import { get, post, del } from "./http";
let baseURL1;
let baseURL2;
let baseURL3;
let domain = {
  Base_M1_URL: "https://hs.darsing.net/project/digital2",
  Base_M2_URL: "https://public-api.solscan.io",
  Base_M3_URL: "https://api.solscan.io",
};

if (process.env.NODE_ENV === "development") {
  baseURL1 = "/api";
  baseURL2 = "";
  baseURL3 = "";
} else if (process.env.NODE_ENV === "debug") {
  baseURL1 = "";
  baseURL2 = "";
  baseURL3 = "";
} else if (process.env.NODE_ENV === "production") {
  baseURL1 = "";
  baseURL2 = "";
  baseURL3 = "";
}
// 登录/注册
// 手机号注册
export const postRegisterApi = (p) => post(baseURL1 + "/imember/pregister", p);
// 手机号密码登录
export const postPasswordLoginApi = (p) =>
  post(baseURL1 + "/imember/pwlogin", p);
// 账号密码登录
export const postUidLoginApi = (p) => post(baseURL1 + "/imember/uidwlogin", p);
// 手机号验证码登录
export const postCodeLoginApi = (p) => post(baseURL1 + "/imember/pclogin", p);
// 修改密码
export const postEditPasswordApi = (p) => post(baseURL1 + "/imember/cpwd", p);
// 忘记密码
export const postResetPasswordApi = (p) =>
  post(baseURL1 + "/imember/reset_pwd", p);
// 检验手机号占用
export const getOccupyPhoneApi = (p) =>
  get(baseURL1 + "/imember/phone_exsits", p);
// 检验UID占用
export const getOccupyUidApi = (p) => get(baseURL1 + "/imember/uid_exsits", p);

// 用户相关
// 用户资料
export const getUserInfoApi = (p) => get(baseURL1 + "/imember/info", p);
// 修改用户资料
export const postEditUserInfoApi = (p) =>
  post(baseURL1 + "/imember/cprofile", p);
// 操作日志
export const getMemberLogApi = (p) => get(baseURL1 + "/imember/log", p);
// 操作报表统计
export const getMemberLogStatisticApi = (p) =>
  get(baseURL1 + "/imember/log/statistic", p);
// 操作报表查询
export const getMemberLogSearchApi = (p) =>
  get(baseURL1 + "/imember/log/search", p);
// 短信相关
// 发送短信
export const postSendMessageApi = (p) => post(baseURL1 + "/imsgcode/send", p);

// 农场相关
// 农场列表
export const getFarmListApi = (p) => get(baseURL1 + "/imanor/list", p);
// 修改农场信息
export const postEditFarmApi = (p) => post(baseURL1 + "/imanor/edit", p);
// 农场详情
export const getFarmInfoApi = (p) => get(baseURL1 + "/imanor/info", p);

// 地块相关
// 地块列表
export const getPlotListApi = (p) => get(baseURL1 + "/iplot/list", p);
// 修改地块信息
export const postEditPlotInfoApi = (p) => post(baseURL1 + "/iplot/edit", p);
// 修改地块状态
export const postEditPlotStateApi = (p) =>
  post(baseURL1 + "/iplot/edit_state", p);
// 删除地块
export const delPlotApi = (p) => del(baseURL1 + "/iplot/del", p);
// 添加地块
export const postAddPlotApi = (p) => post(baseURL1 + "/iplot/add", p);
// 地块详情
export const getPlotInfoApi = (p) => get(baseURL1 + "/iplot/info", p);

// 产品相关（作物）
// 产品列表
export const getProductListApi = (p) => get(baseURL1 + "/iproduct/list", p);
// 修改产品信息
export const postEditProductInfoApi = (p) =>
  post(baseURL1 + "/iproduct/edit", p);
// 修改产品状态
export const postEditProductStateApi = (p) =>
  post(baseURL1 + "/iproduct/state", p);
// 删除产品
export const deleteProductApi = (p) => del(baseURL1 + "/iproduct/del", p);
// 添加产品
export const postAddProductApi = (p) => post(baseURL1 + "/iproduct/add", p);
// 产品详情
export const getProductInfoApi = (p) => get(baseURL1 + "/iproduct/info", p);

// 任务相关
// 任务列表
export const getTaskListApi = (p) => get(baseURL1 + "/itask/list", p);
// 修改任务信息
export const postEditTaskApi = (p) => post(baseURL1 + "/itask/edit", p);
// 修改任务状态
export const postEditTaskStateApi = (p) => post(baseURL1 + "/itask/state", p);
// 删除任务
export const deleteTaskApi = (p) => del(baseURL1 + "/itask/del", p);
// 添加任务
export const postAddTaskApi = (p) => post(baseURL1 + "/itask/add", p);
// 任务详情
export const getTaskInfoApi = (p) => get(baseURL1 + "/itask/info", p);
// 任务统计
export const getTaskCountApi = (p) => get(baseURL1 + "/itask/count", p);
//  农事任务统计
export const getTaskStatisticsApi = (p) =>
  get(baseURL1 + "/itask/statistics", p);

// 批次相关
// 批次列表
export const getBatchListApi = (p) => get(baseURL1 + "/ibatch/list", p);
// 修改批次信息
export const postEditBatchApi = (p) => post(baseURL1 + "/ibatch/edit", p);
// 修改批次状态
export const postEditBatchStateApi = (p) => post(baseURL1 + "/ibatch/state", p);
// 删除批次
export const deleteBatchApi = (p) => del(baseURL1 + "/ibatch/del", p);
// 添加批次
export const postAddBatchApi = (p) => post(baseURL1 + "/ibatch/add", p);
// 批次详情
export const getBatchInfoApi = (p) => get(baseURL1 + "/ibatch/info", p);

// 流程相关
// 流程列表
export const getFlowListApi = (p) => get(baseURL1 + "/iflow/list", p);
// 修改流程信息
export const postFlowEditApi = (p) => post(baseURL1 + "/iflow/edit", p);
// 删除流程
export const delFlowApi = (p) => del(baseURL1 + "/iflow/del", p);
// 添加流程
export const postAddFlowApi = (p) => post(baseURL1 + "/iflow/add", p);
// 批量添加流程
export const postAddsFlowApi = (p) => post(baseURL1 + "/iflow/adds", p);
// 流程详情
export const getFlowInfoApi = (p) => get(baseURL1 + "/iflow/info", p);

// 作物分类相关（作物）
// 作物分类列表
export const getProductClassListApi = (p) =>
  get(baseURL1 + "/iproduct_class/list", p);
// 修改作物分类信息
export const postEditProductClassApi = (p) =>
  post(baseURL1 + "/iproduct_class/edit", p);
// 删除作物分类
export const delProductClassApi = (p) =>
  del(baseURL1 + "/iproduct_class/del", p);
// 添加作物分类
export const postAddProductClassApi = (p) =>
  post(baseURL1 + "/iproduct_class/add", p);
// 作物分类详情
export const getProductClassInfoApi = (p) =>
  get(baseURL1 + "/iproduct_class/info", p);

// 农资相关
// 农资列表
export const getResourceListApi = (p) => get(baseURL1 + "/iresource/list", p);
// 农资记录
export const getResourceSheetApi = (p) => get(baseURL1 + "/iresource/sheet", p);
// 修改农资信息
export const getResourceEditApi = (p) => post(baseURL1 + "/iresource/edit", p);
// 添加农资记录
export const getResourceStockApi = (p) =>
  post(baseURL1 + "/iresource/stock", p);
// 修改产品状态
export const getResourceStateApi = (p) =>
  post(baseURL1 + "/iresource/state", p);
// 删除农资
export const delResourceApi = (p) => del(baseURL1 + "/iresource/del", p);
// 添加农资
export const postAddResourceApi = (p) => post(baseURL1 + "/iresource/add", p);
// 产品详情
export const getAddResourceApi = (p) => get(baseURL1 + "/iresource/info", p);

// 农资分类相关
// 农资分类列表
export const getResourceClassListApi = (p) =>
  get(baseURL1 + "/iresource_class/list", p);
// 修改农资分类信息
export const postEditResourceClassApi = (p) =>
  post(baseURL1 + "/iresource_class/edit", p);
// 删除农资分类
export const delResourceClassApi = (p) =>
  del(baseURL1 + "/iproduct_class/del", p);
// 添加农资分类
export const getAddResourceClassApi = (p) =>
  post(baseURL1 + "/iresource_class/add", p);
// 农资分类详情
export const getResourceClassInfoApi = (p) =>
  get(baseURL1 + "/iresource_class/info", p);

// 溯源相关
// 溯源信息记录
export const getTranceAppendApi = (p) => post(baseURL1 + "/itrance/append", p);
// 溯源信息查询
export const getTranceSearchApi = (p) => get(baseURL1 + "/itrance/search", p);
// 溯源查询记录 没用
export const getTranceLogApi = (p) => get(baseURL1 + "/itrance/log", p);
// 溯源记录删除
export const delResourceItemApi = (p) => del(baseURL1 + "/itrance/del_item", p);
// 溯源统计
export const getTranceStatisticApi = (p) =>
  get(baseURL1 + "/itrance/statistic", p);
// 溯源商品列表
export const getTranceProductListApi = (p) =>
  get(baseURL1 + "/itrance/product/list", p);
// 溯源top列表
export const getTranceTopListApi = (p) =>
  get(baseURL1 + "/itrance/top/list", p);

// 工单
// 二维码列表
export const getQrcodeListApi = (p) => get(baseURL1 + "/iqrcode/temp_list", p);
// 信息详情
export const getQrcodeInfoApi = (p) => get(baseURL1 + "/iqrcode/temp_info", p);
// 工单列表
export const getOrderListApi = (p) => get(baseURL1 + "/iqrcode/order_list", p);
// 提交添加工单
export const postaddOrderApi = (p) => post(baseURL1 + "/iqrcode/order_add", p);
// 删除工单
export const delOrderApi = (p) => del(baseURL1 + "/iqrcode/order_del", p);

// 设备云
// 设备分组
export const getEquipmentGroupApi = (p) =>
  get(baseURL1 + "/equipment/get_group", p);
// 设备统计
export const getEquipmentCountApi = (p) =>
  get(baseURL1 + "/equipment/get_count", p);
// 设备列表
export const getEquipmentListApi = (p) =>
  get(baseURL1 + "/equipment/get_list", p);
// 获取可预警设备列表
export const getMonitorEquipmentListApi = (p) =>
  get(baseURL1 + "/imonitor/equiplist", p);
// 设备详情
export const getEquipmentInfoApi = (p) =>
  get(baseURL1 + "/equipment/get_info", p);
// 设备节点列表
export const getEquipmentChildListApi = (p) =>
  get(baseURL1 + "/equipment/get_child_list", p);
// 设备节点数据
export const getEquipmentChildListInfoApi = (p) =>
  get(baseURL1 + "/equipment/get_child_info", p);
// 设备节点设置
export const postSetEquipmentChildListInfoApi = (p) =>
  post(baseURL1 + "/equipment/set_child_info", p);
// 视频接口
// 获取视频 quality=1高清，2畅通
export const getVideoAddressApi = (p) => get(baseURL1 + "/video/address", p);
// 移动摄像头 speed:1 act:start
export const postMoveVideoApi = (p) => post(baseURL1 + "/video/move", p);
// 发语音到摄像头
export const postVoiceVideoApi = (p) => post(baseURL1 + "/video/voice", p);

// 人员相关
// 人员列表
export const getMemberListApi = (p) => get(baseURL1 + "/isub_member/list", p);
// 人员详情
export const getMemberInfoApi = (p) => get(baseURL1 + "/isub_member/info", p);
// 删除人员
export const delMemberApi = (p) => del(baseURL1 + "/isub_member/del", p);
// 添加人员
export const postAddMemberApi = (p) => post(baseURL1 + "/isub_member/add", p);
// 修改人员信息
export const postEditMemberInfoApi = (p) =>
  post(baseURL1 + "/isub_member/edit", p);
// 修改人员状态
export const postEditMemberStateApi = (p) =>
  post(baseURL1 + "/isub_member/state", p);

// 首页地图相关
export const getAllMarkDataApi = (p) =>
  get(baseURL1 + "/imap/all_mark_data", p);
// 天气
export const getWeatherApi = (p) => get(baseURL1 + "/weather", p);

// 上传
// https://resource.darsing.net/v1/img
// https://resource.darsing.net/v1/file

// 消息相关
// 未读消息数量
export const getMsgApi = (p) => get(baseURL1 + "/user_msg/count", p);
// 消息列表
export const getMsgListApi = (p) => get(baseURL1 + "/user_msg/list", p);
// 消息详情
export const getMsgInfoApi = (p) => get(baseURL1 + "/user_msg/info", p);
// 批量删除消息
export const delMsgApi = (p) => del(baseURL1 + "/user_msg/del", p);

// 知识云相关
// 文章列表
export const getArticleListApi = (p) => get(baseURL1 + "/iarticle/list", p);
// 文章详情
export const getArticleInfoApi = (p) => get(baseURL1 + "/iarticle/info", p);

// 知识云 获取 作物类型 列表
export const getFamilyListApi = (p) =>
  get(baseURL1 + "/ilibrary/crop_family", p);
// 获取 作物类别 列表
export const getCategoryListApi = (p) =>
  get(baseURL1 + "/ilibrary/crop_category", p);
// 获取 作物 列表
export const getCropListApi = (p) => get(baseURL1 + "/ilibrary/crop_list", p);
// 获取 病虫害类型 列表
export const getDiseaseTypeListApi = (p) =>
  get(baseURL1 + "/ilibrary/disease_type", p);
// 获取 病虫害 列表
export const getDiseaseListApi = (p) =>
  get(baseURL1 + "/ilibrary/disease_list", p);
// 显示病虫害详情
export const getDiseaseDetailApi = (p) =>
  get(baseURL1 + "/ilibrary/disease_info", p);

// 病虫害 改版 接口
// 获取 作物类别 列表
export const getCategoryListV2Api = (p) =>
  get(baseURL1 + "/ilibrary/crop_category_v2", p);
// 获取 病虫害 列表
export const getDiseaseListV2Api = (p) =>
  get(baseURL1 + "/ilibrary/disease_list_v2", p);
// 杂草分类列表
export const getWeedCategoryListApi = (p) =>
  get(baseURL1 + "/ilibrary/weed_category", p);
// 杂草列表
export const getWeedListApi = (p) => get(baseURL1 + "/ilibrary/weed_list", p);
// 杂草详情
export const getWeedInfoApi = (p) => get(baseURL1 + "/ilibrary/weed_info", p);

// 报警相关
// 获取报警列表
export const getMonitorListApi = (p) => get(baseURL1 + "/imonitor/list", p);
// 提交报警
export const postMonitorAppendApi = (p) =>
  post(baseURL1 + "/imonitor/append", p);
// 获取报警信息
export const getMonitorInfoApi = (p) => get(baseURL1 + "/imonitor/info", p);
// 修改报警信息
export const postMonitorEditApi = (p) => post(baseURL1 + "/imonitor/edit", p);
// 更改报警状态
export const postMonitorEditOffApi = (p) => post(baseURL1 + "/imonitor/off", p);
export const postMonitorEditOnApi = (p) => post(baseURL1 + "/imonitor/on", p);
// 删除
export const delMonitorDelApi = (p) => del(baseURL1 + "/imonitor/del", p);
// 供应商相关
// 供应商列表
export const getLinkmanListApi = (p) => get(baseURL1 + "/ilinkman/list", p);
// 供应商详情
// linkman_id
export const getLinkmanInfoApi = (p) => get(baseURL1 + "/ilinkman/info", p);
// 供应商添加
export const postAddLinkmanApi = (p) => post(baseURL1 + "/ilinkman/add", p);
// 供应商编辑
export const postEditLinkmanApi = (p) => post(baseURL1 + "/ilinkman/edit", p);
// 供应商删除
export const delLinkmanApi = (p) => del(baseURL1 + "/ilinkman/del", p);

// 农场秀
// 农场秀列表
export const getFarmShowListApi = (p) => get(baseURL1 + "/imanor/show/list", p);
// 农场秀详情
export const getFarmShowInfoApi = (p) => get(baseURL1 + "/imanor/show/info", p);
// 农场秀添加
export const postAddShowApi = (p) => post(baseURL1 + "/imanor/show/add", p);
// 编辑
export const postEditShowApi = (p) => post(baseURL1 + "/imanor/show/edit", p);
// 农场秀删除
export const delShowApi = (p) => del(baseURL1 + "/imanor/show/del", p);

//  上链
export const getUplinkAll = (p) =>
  get(baseURL2 + "/all", p, domain.Base_M2_URL);
export const getUplinkInitialize = (p) =>
  get(baseURL2 + "/initialize", p, domain.Base_M2_URL);
// 添加
export const getUplinkReset = (p) =>
  get(baseURL2 + "/reset", p, domain.Base_M2_URL);
// 添加 （耗时严重）
export const getUplinkAppend = (p) =>
  get(baseURL2 + "/append", p, domain.Base_M2_URL);
export const postTranceChainApi = (p) => post(baseURL1 + "/itrance/chain", p);

// 链上状态
export const getLinkStatusInfoApi = (p) =>
  get(baseURL2 + "/chaininfo", p, domain.Base_M2_URL);
// 链上表格
export const getLinkChartApi = (p) =>
  get(baseURL3 + "/amm/chart", p, domain.Base_M3_URL);

// 含山项目相关

// 获取含山镇列表
export const getTownListApi = (p) => get(baseURL1 + "/hanshan/town/list", p);
// 添加镇
export const postTownAddApi = (p) => post(baseURL1 + "/hanshan/town/add", p);
// 编辑镇
export const postTownEditApi = (p) => post(baseURL1 + "/hanshan/town/edit", p);
// 删除镇
export const delTownApi = (p) => post(baseURL1 + "/hanshan/town/del", p);

// 获取含山村列表
export const getVillageListApi = (p) =>
  get(baseURL1 + "/hanshan/village/list", p);
// 添加村
export const postVillageAddApi = (p) =>
  post(baseURL1 + "/hanshan/village/add", p);
// 编辑村
export const postVillageEditApi = (p) =>
  post(baseURL1 + "/hanshan/village/edit", p);
// 村详情
export const getVillageInfoApi = (p) =>
  get(baseURL1 + "/hanshan/village/info", p);
// 删除村
export const delVillageApi = (p) => post(baseURL1 + "/hanshan/village/del", p);
// 大屏接口
// 水稻种植统计（也同于首页水稻）
export const getCls1Api = (p) =>
  get(baseURL1 + "/hanshan/crops/statistics/cls1", p);
// 种植情况统计
export const getCls2Api = (p) =>
  get(baseURL1 + "/hanshan/crops/statistics/cls2", p);
// 种植分布统计
export const getCls3Api = (p) =>
  get(baseURL1 + "/hanshan/crops/statistics/cls3", p);
// 凌家滩水稻种植统计
export const getLJTStatisticApi = (p) =>
  get(baseURL1 + "/hanshan/lingjiatan/statistic", p);
// 根据镇片区村查询统计结果
export const getStatisticApi = (p) => get(baseURL1 + "/hanshan/statistic", p);
// 片区绘制/编辑
export const postScopeMarkDataApi = (p) =>
  post(baseURL1 + "/hanshan/scope/markdata", p);
// 片区绘制详情
export const getTownInfoApi = (p) =>
  get(baseURL1 + "/hanshan/get/scope/markdata", p);
// 首页片区区域
export const getMapScopeApi = (p) =>
  get(baseURL1 + "/hanshan/scope/mark/data", p);
// 首页镇区域
export const getMapTownApi = (p) =>
  get(baseURL1 + "/hanshan/get/town/markdata", p);

// 品牌含山 列表
export const getBrandListApi = (p) => get(baseURL1 + "/hanshan/brand/list", p);
// 含山公司 列表
export const getCompanyListApi = (p) =>
  get(baseURL1 + "/hanshan/company/list", p);

// 片区管理
//列表
export const getScopeListApi = (p) => get(baseURL1 + "/hanshan/scope/list", p);
// 添加片区
export const postAddScopeListApi = (p) =>
  post(baseURL1 + "/hanshan/scope/add", p);
// 编辑片区
export const postEditScopeListApi = (p) =>
  post(baseURL1 + "/hanshan/scope/edit", p);
// 删除片区
export const postDelScopeListApi = (p) =>
  post(baseURL1 + "/hanshan/scope/del", p);

// 首页溯源记录
export const getHanshanTranceListApi = (p) =>
  get(baseURL1 + "/hanshan/trance/list", p);
// 首页种植信息
export const getHanshanPlantingInfoApi = (p) =>
  get(baseURL1 + "/hanshan/planting/info", p);
// 首页种植信息
export const getHanshanTranceInfoApi = (p) =>
  get(baseURL1 + "/hanshan/trance/info", p);

// 农产品质量安全记录
//记录列表
export const getHanshanRecordListApi = (p) =>
  get(baseURL1 + "/hanshan/product/record/list", p);
// 添加
export const postHanshanRecordAddApi = (p) =>
  post(baseURL1 + "/hanshan/product/record/add", p);
// 编辑
export const postHanshanRecordEditApi = (p) =>
  post(baseURL1 + "/hanshan/product/record/edit", p);
// 删除
export const postHanshanRecordDelApi = (p) =>
  post(baseURL1 + "/hanshan/product/record/del", p);
// 负责人列表
export const getHanshanUserListApi = (p) =>
  get(baseURL1 + "/hanshan/user/list", p);

// 烘干管理
// 烘干计划
export const getDryPlanListApi = (p) => get(baseURL1 + "/hanshan/produce/plan/list", p);
export const postAddDryPlanApi = (p) => post(baseURL1 + "/hanshan/produce/plan/add", p);
export const postEditDryPlanApi = (p) => post(baseURL1 + "/hanshan/produce/plan/edit", p);
export const delDryPlanApi = (p) => post(baseURL1 + "/hanshan/produce/plan/del", p);
// 烘干记录
export const getDryListApi = (p) => get(baseURL1 + "/hanshan/dry/list", p);
export const postAddDryApi = (p) => post(baseURL1 + "/hanshan/dry/add", p);
export const postEditDryApi = (p) => post(baseURL1 + "/hanshan/dry/edit", p);
export const delDryApi = (p) => post(baseURL1 + "/hanshan/dry/del", p);
// 加工管理
export const getProcessListApi = (p) => get(baseURL1 + "/hanshan/process/list", p);
export const postAddProcessApi = (p) => post(baseURL1 + "/hanshan/process/add", p);
export const postEditProcessApi = (p) => post(baseURL1 + "/hanshan/process/edit", p);
export const delProcessApi = (p) => post(baseURL1 + "/hanshan/process/del", p);
export const getProcessStatisticsApi = (p) => get(baseURL1 + "/hanshan/process/statistics", p);
// 成品仓管理
export const getStockListApi = (p) => get(baseURL1 + "/hanshan/stock/list", p);
export const postStockOutApi = (p) => post(baseURL1 + "/hanshan/stock/out", p);
